.App {
  font-family: 'Raleway', sans-serif;
  /* Allow full screen  */
  height: 100vh;
}

.App .navbar-brand {
  font-weight: bold;
}

.App.container {
  padding: 0;
}

div#root {
  background-color: #F8F7F2;
}

input:focus {
  outline:none !important;
}